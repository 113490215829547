import React from "react";
import styled from "styled-components";

const InitiateWrap = styled.ul`
  max-width: 830px;
`;

const Initiate = () => {
  return (
    <InitiateWrap>
        <li className="content-header">.// CULT OF NEON \\.</li>
        <li className="cult-link content-entry content-body text-center">No schematics detected in this wallet.</li>
        <br/>
        <li className="content-body text-center">All neon include a Schematic NFT that unlocks hidden files and more for the artwork.</li>
    </InitiateWrap>
  );
};

export default Initiate;