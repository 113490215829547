import React from 'react'
import styled from "styled-components";
import web3 from 'web3';
import LuxWeb3Context from '../../context/LuxWeb3Context';
import { DIRECT_ITEM_URL } from '../../constants';

// CONTENT COMPONENTS
import { device } from "../../utils";
import NftMedia from './NftMedia';
import NftDescription from './NftDescription';



// ~~~~ STYLING
const NftGalleryItemWrap = styled.div`
  position: relative;
  overflow: visible;
  width: 100%;
  margin-bottom: 50px;
  display: inline-flex;
  flex-wrap: wrap;

  @media ${device.md} {
    width: calc( 50% - 10px );
  }

  @media ${device.xl} {
    width: calc( 33.3% - 10px );
  }


  // IDENTIFY NUMBER
  .item-number {
    position: absolute;
    top: -33px;
    left: -3px;
    padding: 0 20px;
    font-weight: bold;
    font-size: 20px;
    border: 3px solid #333;
    border-bottom: none;
  }


  // CONTENT PANE CONTROLS
  .nft-content-pane {
    transition: opacity 0.5s ease-in-out;

    &.content-main { 
      opacity: 1; 
      pointer-events: all;
    }

    &.content-info { 
      position: absolute;
      top: 0;
      left: 0;
      height: calc( 100% - 80px );
      opacity: 0;
      pointer-events: none;
      overflow: auto;
    }
  }

  &.specs-view .nft-content-pane {
    &.content-main { 
      opacity: 0; 
      pointer-events: none;
    }
    &.content-info { 
      opacity: 1; 
      pointer-events: all;
    }
  }



  // TITLE 
  .item-title {
    margin-bottom: 15px;
  }

  // ITEM PROPERTIES
  .item-props {
    text-align: left;
    font-size: 17px;
    display: flex;
    flex-wrap: wrap;
  }

    .prop-item { 
      width: 100%;
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid #000;
      justify-content: space-between;
      padding: 6px 0;

      &:last-child {
        border-bottom-width: 0px;
      }
    }
  }


  // IMAGERY
  .item-media-wrap {
    padding-top: 15px;
  }

  // FORGE BUTTON
  .action-button-wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: auto;
    align-self: flex-end;

    .action-button {
      max-width: 47%;
    }
  }
  .action-button {
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 5px 10px;
    width: 100%;
    font-size: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media ${device.md} {
      font-size: 18px;
    }

    @media ${device.lg} {
      font-size: 22px;
    }

    &:hover {
      background: #FFF;
      color: #000;
      text-shadow: none !important;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.7;
    }
  }
`;


class NftGalleryItem extends React.Component {
  static contextType = LuxWeb3Context;
  constructor(props) {
    super(props);
    this.state = {
      showSpecs: false
    }

    this.toggleSpecs = this.toggleSpecs.bind(this);
  }

  toggleSpecs() {
    this.setState({
      showSpecs: !this.state.showSpecs
    })
  }


  render() {
    const { setNftToForge, setLocationForge } = this.context;
    const { mode, nft } = this.props;
    const { showSpecs } = this.state;

    // ID SETUP
    let id = null;
    let productHandle =  nft?.title?.replace('#','').replace(' // ', '-').toLowerCase();
    if ( mode === 'available' ) {
      id = !!nft && !!nft.tokenId ? web3.utils.toNumber( nft.tokenId ) : null;
    } else {
      id = !!nft && !!nft.id && !!nft.id.tokenId ? web3.utils.toNumber( nft.id.tokenId ) : null;
    }


    // ~~~~ UTIL FUNCTIONS:
    const beginForge = () => {
      setNftToForge(nft);
      setLocationForge();
    }


    // ACTION BUTTON : Show user the buy button, unless we're logged in and showing their 
    //                 collected ones to redeem.
    let actionButton = (
      <div className="action-button-wrap">
        <a className="action-button cult-button" onClick={this.toggleSpecs}>{ showSpecs ? 'Back' : 'Downloads'}</a>
      </div>
    )
    if (mode !== 'available' ) {
      let buttonText = 'REPAIR';
      if ( nft.isInProgress ) {
        buttonText = "IN PROGRESS";
      }
      if ( nft.isForged ) {
        buttonText = "COMPLETE";
      }
      actionButton = (
        <div className="action-button-wrap">
          <a className="action-button cult-button" onClick={this.toggleSpecs}>{ showSpecs ? 'Back' : 'Downloads'}</a>
          <button className="action-button cult-button" onClick={beginForge}>{buttonText}</button>
      </div>
      )
    }




// ~~~~ COMPONENTS
    let nft_properties = null;
    const metadata = !!nft && mode === 'available' ? nft.rawMetadata : nft.metadata;
    if ( !!metadata && Array.isArray(metadata.attributes ) ) {
      const sanitizedProps = metadata.attributes.filter( data => data.trait_type.search(/Cult ID|Artist|Neon Status/i) === -1  )
      nft_properties = sanitizedProps.map( attr => (
        <div className="prop-item" key={attr.trait_type}>
          <div className="prop-title">{attr.trait_type}:</div>
          <div className="prop-value">{attr.trait_type === 'Neon Status' && (nft.isInProgress || nft.isForged) ? 'Claimed' : attr.value}</div>
        </div>
      ));
    }



// ~~~~ COMPONENT BODY
    return (
      <NftGalleryItemWrap className={`cult-panel cultist-owned-item ${showSpecs ? 'specs-view':''}`}>

        {/* NUMBER */ 
          !!id && (
            <div className="item-number cult-panel-colors">#{id}</div> )}


        {/* CONTENT - Main Image + Stats */}
        <div className="content-main nft-content-pane">
        
          {/* MEDIA */}
          <NftMedia className="item-media-wrap" nft={nft} />

          {/* TITLE */}
          <div className="item-title">{metadata.name}</div>

          {/* PROPERTIES */}
          <div className="item-props">{nft_properties}</div>
        </div>


        {/* CONTENT - Info Description */}
        <div className="content-info nft-content-pane">

            {/* DESCRIPTION */}
            <NftDescription downloads={nft.hiddenMedia} description={metadata.description} title={metadata.name} />
        </div>


        {/* FORGE BUTTON */}
        {actionButton}
      </NftGalleryItemWrap>
    )
  }
};

export default NftGalleryItem;
