import React from 'react'
import styled from "styled-components";
import { device } from "../../utils";
import LuxWeb3Context from '../../context/LuxWeb3Context';


// ~~~~ STYLING
const FormWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 0 10px;
  flex-wrap: wrap;
  text-align: left;

  @media ${device.md} {
    width: 40%;
  }

  .input-item {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    justify-content: space-between;

    .input-label {
      width: 100%;
      margin: 0;
    }

    .input-value {
      width: 98%;
      margin-left: 2%;
      margin-top: -4px;
      border: 2px solid #333;

      &:focus {
        background-color: #fff;
        color: #000;
        text-shadow: none;
      }
    }

    .item-note {
      font-size: 14px;
      font-style: italic;
      padding-left: 2%;
    }

    select {
      border: 1px solid #333;
      height: 36px;
      margin-top: -4px;
    }

    // HALF WIDTH ITEMS
    &.half {
      width: 50%;

      .input-value {
        width: 90%;
        margin-left: 4%;
      }
    }

    // ITEM SPECIFICS - Alt contact Dropdown handler
    &.item-alt-contact {
      .input-value,
      select {
        width: 46%;
      }
    }
    #alt-contact-type {
      border: 3px solid #333;
    }
  }

  // SUBMIT BUTTON
  .submit-button {
    position: relative;
    width: 300px;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 15px 0 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 24px;

    &.disable {
      opacity: 0.5; 
      pointer-events: none;
    }
  }
`;


class ForgeForm extends React.Component {
  static contextType = LuxWeb3Context;

  constructor( props, context ) {
    super( props, context );
    const prevData =  context.formData || {};
    this.state = {
      // FORM FIELDS
      name: prevData.name || '',
      email: prevData.email || '',
      explaination: prevData.explaination || '',
      altContact: prevData.altContact || '',
      altContactType: prevData.altContactType || 'twitter',
      address: prevData.address || '',
      city: prevData.city || '',
      state: prevData.state || '',
      zipcode: prevData.zipcode || '',
      country: prevData.country || '',

      // FORM STATE
      invalidFields: [],
      invalidMessage: null,
      isSubmitting: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAltContact = this.handleAltContact.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
    this.updateForm = this.updateForm.bind(this);
  }
  static contextType = LuxWeb3Context;
  

  // UTIL : Handle typing in form
  updateInputValue(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  // UTIL : Set which fields are invalid
  updateForm(fields = [], msg = null, isSubmitting = true ) {
    this.setState({ 
      invalidFields: fields,
      invalidMessage: msg,
      isSubmitting
    });
  }

  // UTIL : Handle submit form data + validation
  async handleSubmit() {
    this.updateForm(); // RESET handling
    let isValid = true;
    let invalidFields = [];
    let invalidMessage = null;
    const notRequired = [ 'altContact', 'altContactType' ];

    // SETUP DATA VALUES
    const data = {
      'name': this.state.name,
      'email': this.state.email,
      'explaination': this.state.explaination,
      'altContact': this.state.altContact,
      'altContactType': this.state.altContactType
    };

    // CHECK KEYS
    for ( var key in data ) {
      const isRequired = notRequired.indexOf(key) === -1;

      // FILLED IN? All Required fields entered
      if ( isRequired && !data[key] ) {
        isValid = false;
        invalidFields.push( key );
        invalidMessage = 'All fields except social handle are required to fulfill a request';
      }

      // VALID EMAIL?
      if ( isRequired && key === 'email' && isValid ) {
        const isValidEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/gi.test( data[key] );
        if ( !isValidEmail ) {
          isValid = false;
          invalidFields.push('email');
          invalidMessage = 'Email invalid, please check it or use another'
        }
      }
    }

    
    // SUBMIT
    let results = {
      status: 'error',
      message: 'Apologies, something went wrong submitting - please try again.'
    }
    if ( isValid ) {
      results = await this.context.submitForgeRequest(data);
      
      // SUBMIT SUCCESS - Show the success msg
      if ( results.status === 'success' ) {
        this.props.isInProgress(true);
      }
      // SUBMIT FAILED - Show reason + release form control 
      else {
        this.updateForm( [], results.message, false );
      }
    }
    // FORM VALIDATION FAILED : Show which fields are bad
    else {
      this.updateForm( invalidFields, invalidMessage, false );
    }
  }

  // UTIL : handleAltContact
  handleAltContact(e) {
    this.setState({ altContactType: e.target.value });
  }



  render() {
    const { invalidFields, isSubmitting } = this.state;

    return (
      <FormWrap key="forge_form_wrap" className={`forge-body ${isSubmitting ? 'submitting' : ''}`}>
        
        {/* FORM ITEM - Name */}
        <div className="input-item item-name">
          <label htmlFor="name" className="input-label">Name*</label>
          <input type="text" name="name" value={this.state.name} onChange={this.updateInputValue} className={`input-value cult-active-border ${invalidFields.indexOf('name') > -1 ? 'cult-input-error': ''}`}></input>
        </div>

        {/* FORM ITEM - Explain Issue */}
        <div className="input-item item-street">
          <label htmlFor="explaination" className="input-label">How can we help?*</label>
          <textarea id="explaination" name="explaination" type="text" value={this.state.explaination} onChange={this.updateInputValue} className={`input-value cult-active-border ${invalidFields.indexOf('explaination') > -1 ? 'cult-input-error': ''}`}></textarea>
        </div>

        {/* FORM ITEM - Email */}
        <div className="input-item item-email">
          <label htmlFor="email" className="input-label">Email*</label>
          <input id="email" name="email" type="email" value={this.state.email} onChange={this.updateInputValue} className={`input-value cult-active-border ${invalidFields.indexOf('email') > -1 ? 'cult-input-error': ''}`}></input>
        </div>

        {/* FORM ITEM - Secondary Contact */}
        <div className="input-item item-alt-contact">
          <label htmlFor="alt-contact" className="input-label">Social Handle</label>
          <input id="alt-contact" name="altContact" type="text" value={this.state.altContact} onChange={this.updateInputValue} className={`input-value cult-active-border ${invalidFields.indexOf('altContact') > -1 ? 'cult-input-error': ''}`}></input>
          <select id="alt-contact-type" value={this.state.altContactType} onChange={this.handleAltContact} className="cult-button-colors" name="alt-contact-type">
            <option value="twitter">Twitter</option>
            <option value="instagram">Instagram</option>
            <option value="discord">Discord</option>
          </select>
          <div className="item-note">Optional - prevents communication delays</div>
        </div>


        {/* SUBMIT */}
        <button className={`submit-button cult-button ${isSubmitting ? 'disable':''}`} onClick={this.handleSubmit}>{isSubmitting ? 'SENDING..' : 'SUBMIT'}</button>

        {/* ERROR MESSAGE */
          !!this.state.invalidMessage && (
            <div className="error-text">{this.state.invalidMessage}</div>)}

      </FormWrap>
    )
  }
};

export default ForgeForm;
