import React from "react";
import styled from "styled-components";

const ContentForgeWrap = styled.div`

`;

const Forge = () => {
  return (
    <ContentForgeWrap>
      <ul>
        <li className="content-header">_.// Repair Neon \\._</li>
        <li className="content-body">We'll arrange shipment to us via email, reaching this form with your wallet verifies you are the artwork owner. We'll update you as any repair progresses, and send a tracking number once shipped back to you.</li>
      </ul>
    </ContentForgeWrap>
   
  );
};

export default Forge;