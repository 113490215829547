import React from "react";
import { device, theme } from "../../../utils";
import styled from "styled-components";
import ContentAbout from "./about";
import TermsOfService from "./termsOfService";
import PrivacyPolicy from "./privacyPolicy";
import Returns from "./returns";
import Contact from "./contact";
import LuxWeb3Context from "../../../context/LuxWeb3Context";

const ContentLegalWrap = styled.div`
  position: relative;

  #info-content {
    padding-top: 10px;

    @media ${device.md} {
      padding-top: 20px;
    }



    // HEADER
    .content-header {
      margin-bottom: 10px;
      font-size: 20px;
      text-shadow: 0 0 0 #000;
      cursor: pointer;
      text-align: center;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      max-width: 400px;
      width: 95%;

      @media ${device.sm} {
        font-size: 24px;
      }

      @media ${device.md} {
        font-size: 27px;
        margin-bottom: 10px;
      }

      &.cult-button {
        margin-bottom: 15px;
      }
    }

    .text-subheader {
      font-size: 18px;
      text-shadow: 0 0 1px #000;
      margin: 10px 0;
      line-height: 18px;

      @media ${device.md} {
        font-size: 27px;
        line-height: 22px;
        margin-top: 15px;
      }
    }


    // BODY CONTENT
    .body-wrap {
      height: 0;
      overflow: hidden;

      &.open {
        height: 100%;
        overflow: visible;
      }
    }

    .content-body {
      font-size: 16px;
      padding: 0 5px;
      text-align: center;
      
      @media ${device.lg} {
        font-size: 20px;
        padding: 0 90px;
      }

      &.content-entry {
        @media ${device.md} {
          font-size: 26px;
        }
      }

      .content-image {
        width: 100%;
      }

      &.image-wrap img {
        max-width: 850px;
      }

      .exchange-image-wrap {
        position: relative;
        margin-bottom: 40px !important;

        .text-subheader.nft-text,
        .text-subheader.claimed-neon-text {
          position: absolute;
          bottom: -40px;
        }
        .text-subheader.nft-text {
          left: 10px;

          @media ${device.md} {
            left: 87px;
          }
        }
        .text-subheader.claimed-neon-text {
          right: 10px;
          
          @media ${device.md} {
            right: 87px;
          }
        }
      }
      

      .text-bold {
        text-shadow: 0px 0px 1px #000;
        color: ${theme.cultPalette[1] === '#950AFF' ? '#cecece':'inherit'};
      }
    }
  
    // UTIL
    .text-center {
      text-align: center;
    }
  }
`;

class ContentLegal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTab: null
    }
    this.open = this.open.bind(this);
  }
  static contextType = LuxWeb3Context;

  componentDidUpdate(prevProps, prevState) {
    const { openTab } = this.state;
    const prevTab = prevState.openTab;

    if ( !!openTab && openTab !== prevTab ) {
      this.resetContentScroll( openTab );
    }
  }

  open(e) {
    const id = e.target.id;
    const selection = id && this.state.openTab !== id ? id : null;
    this.setState({openTab: selection});
  }

  resetContentScroll(selection) {
    const scrollBox = document.getElementById('content-scroll-box');
    const scrollTarget = document.getElementById( selection );
    if ( !!scrollBox && !!scrollBox.scrollTo && !!scrollTarget ) {
      scrollBox.scrollTo(0, 0);
      const targetLocation = scrollTarget.getBoundingClientRect();
      const parentLocation = scrollBox.getBoundingClientRect();
      const distanceToScroll = targetLocation.top - parentLocation.top - 20;
      // console.log( `selection top = ${targetLocation.top} \ntarget top    = ${parentLocation.height} \nscroll amount = ${distanceToScroll}` );
      scrollBox.scrollTo(0, distanceToScroll);
    } 
  }

  render() {
    const { openTab } = this.state;

    return (
      <ContentLegalWrap>
        <ul id="info-content" ref="">
          <ContentAbout noNftsFound={this.props.noNftsFound}/>
          <br/>
          <Contact />

          {/* PRIVACY + DATA */}
          <li className="content-header cult-button" id="privacy" onClick={this.open}>.|' PRIVACY POLICY '|.</li>
          <div className={openTab === 'privacy' ? 'body-wrap open': 'body-wrap'}>
            <PrivacyPolicy />
          </div>


          {/* TERMS OF SERVICE */}
          <li className="content-header cult-button" id="terms" onClick={this.open}>!|' TERMS OF SERVICE '|!</li>
          <div className={openTab === 'terms' ? 'body-wrap open': 'body-wrap'}>
            <TermsOfService />
          </div>


          {/* RETURNS */}
          <li className="content-header cult-button" id="returns" onClick={this.open}>:'| RETURNS |':</li>
          <div className={openTab === 'returns' ? 'body-wrap open': 'body-wrap'}>
            <Returns />
          </div>
          
        </ul>
      </ContentLegalWrap>
    );
  }
};

export default ContentLegal;