import React from 'react'
import styled from "styled-components";
import { device } from "../../utils";
import LuxWeb3Context from '../../context/LuxWeb3Context';

// CONTENT COMPONENTS
import NftMedia from '../Web3Utils/NftMedia';
import ForgeForm from './ForgeForm';
import ForgeInProgress from './ForgeInProgress';
import ForgeDone from './ForgeDone';


// ~~~~ STYLING
const ForgeFormWrap = styled.div`
  position: relative;
  top: 20px;
  left: 50%;
  transform: translateX( -50% );
  width: 100%;
`;  

const TextBox = styled.div`
  display: flex;
  flex-wrap: wrap;

  .forge-image {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;

    @media ${device.md} {
      width: 60%;
      padding-right: 20px;
      padding-bottom: 5px;
      padding-left: 15px;
    }
  }
`;


class ForgePanel extends React.Component {
  constructor( props, context ) {
    super( props, context );
    const { isForged, isInProgress, forgeStatus } = this.context.NftInForge;
    this.state = {
      isForged: isForged || false,
      isInProgress: isInProgress || false,
      forgeStatus: forgeStatus || 'Unforged' // NOTE: This isn't really actually used anywhere, toggles are what determine the status
    }
    this.isInProgress = this.isInProgress.bind(this);
  }
  static contextType = LuxWeb3Context;

  isInProgress(isInProgress = false) {
    this.setState({ isInProgress });
  }

  render() {
    const { NftInForge, setLocationReliquary } = this.context;
    const { isForged, isInProgress } = this.state;

    return (
      <ForgeFormWrap>
        <TextBox className="cult-panel">

          {/* HEADER - NFT SHOWN */}
          <div className="forge-image">
            <NftMedia nft={NftInForge} /> 
          </div>


          {/* BODY - FORM SHOWN */
            ( !isForged && !isInProgress ) && (
              <ForgeForm key="forge-form" isInProgress={this.isInProgress} />)}

          { /* BODY - FORGE IN PROGRESS */
            ( isInProgress && !isForged ) && (
              <ForgeInProgress finish={setLocationReliquary} />)}

          { /* BODY - FORGE COMPLETE */
            isForged && (
              <ForgeDone finish={setLocationReliquary} />)}

        </TextBox>
      </ForgeFormWrap>
    )
  }
};

export default ForgePanel;
