import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';
import { COLLECTION_URL } from "../../constants";
import { device } from "../../utils";
import EyeLogo from '../../assets/image/svg/eye_logo.svg';
import TheThreeSampler from '../../assets/image/cult/first_3_sampler.png';
import NavMenu from './NavMenu';


// ~~~~ STYLING
const HeaderPanelWrap = styled.div`
  position: absolute;
  width: 100vw;
  padding: 10px;
  padding-right: 13px; 

  &.enlightenment-mode {
    .pane-profile,
    .info-button,
    .pane-location {
      opacity: 0;
      pointer-events: none;
    }

    .enlightenment {
      top: 10px;
    }
  }

  &.menu-open .right-pane-content {
    z-index: 2000;
  }

  &.menu-close .right-pane-content {
    transition: 0.25s z-index 0s ease-in-out;
    z-index: 100;
  }
`;  

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;

  &.alert-row {
    position: absolute;
    z-index: 5000;
    justify-content: center;
    margin-top: 85px;
    cursor: pointer;

    .pane-error {
      z-index: 10;
      padding: 5px;
  
      @media ${device.md} {
        padding: 5px 20px;
      }
    }
  }
`;

const TextBox = styled.div`
  /* PANE : PROFILE */
  &.pane-profile {
    position: relative;
    display: inline-flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 14px;
    margin-top: -5px;
    left: -5px;
    padding: 5px 10px;
    transition: all 0.25s ease-in-out;
    overflow: visible;
    max-width: calc(100vw - 169px);

    .welcome-long {
      display: none;
    }
    .welcome-short {
      font-size: 11px;

      @media only screen and (min-width:363px ) {
        font-size: 15px;
      }
    }

    @media ${device.sm} {
      .welcome-short { display: none; }
      .welcome-long  { display: inline-flex; }
    }

    &.profile-wallet-connected {
      margin-top: 0;
      left: 2px;

      @media only screen and (max-width:430px) {
        font-size: 14px;
      }

      @media only screen and (max-width:360px) {
        font-size: 12px;
      }

      @media only screen and (max-width:340px) {
        font-size: 11px;
      }
    }

    .cult-button {
      font-size: 20px;
      margin-top: 23px;

      @media ${device.sm} {
        font-size: 24px;
      }
    }

    @media ${device.md} {
      padding: 5px 20px;
    }

    .address-ens {
      max-width: 320px;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .address-ens,
    .address-long {
      padding-right: 25px;
    }

    .logout-button {
      position: absolute;
      cursor: pointer;
      padding: 0 7px 0 10px;
      font-size: 20px;
      margin-bottom: 5px;
      right: 1px;
      top: 5px;

      @media only screen and (max-width:430px) {
        top: 0px;
      }
    }
  }

  /* PANE : LOCATION */
  &.pane-location {
    display: none;
    position: absolute;
    right: 87px;
    top: 10px; 
    font-size: 26px;
    padding: 5px 14px 5px 20px;
    transition: all 0.25s ease-in-out;
    
    @media ${device.md} {
      display: inline-flex;
    }

    // HOME HIDE OVERRIDE
    &.current-location-home {
      display: none;
    }
  }

  /* PANE : ABOUT LINK */
  &.pane-button {
    cursor: pointer;
    font-size: 30px;
    padding: 2px 17px 2px 19px;
    transition: opacity 0.25s ease-in-out;

    &.link-about {
      font-size: 50px;
      line-height: 40px;
      padding: 1px 0 0 7px;
      height: 55px;
      width: 57px;
    }

    &.link-home,
    &.link-reliquary {
      padding: 2px 14px 2px 17px;
    }

    &:hover {
      background: #eee !important;
      color: #000 !important;
      text-shadow: 2px 2px 0px rgba(0,0,0,0) !important;
    }
  }


  /* PANE BUTTON : ENLIGHTENMENT */
  &.enlightenment {
    position: absolute;
    top: 79px;
    width: 57px;
    height: 56px;
    transition: all 0.25s ease-in-out;

    svg {
      display: none;
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;


class HeaderPanel extends React.Component {
  constructor( props ) {
    super( props );
    this.clearErrorMsg = this.clearErrorMsg.bind(this);
  }
  static contextType = LuxWeb3Context;

  clearErrorMsg() {
    const { setWalletConnectError } = this.context;
    setWalletConnectError(null);
  }

  logoutUser() {
    window.location.reload();
  }


  render() {
    const { 
      enlightenmentMode, 
      isMenuOpen,
      isWalletConnected, 
      location, 
      memberCount,
      memberLevel, 
      walletAddress, 
      walletEns, 
      web3Error 
    } = this.context;


// ~~~~ UI : HEADER : VISUAL PARTS OF THE UI
// -----------------------------------------
  // UI : LEFT BOX - Profile or Title Box
      let cleanAddress = '';
      const buyButtonText = Date.now() < new Date(1666389600000) ? 'First Drop:<br/>OCT.21 @ 3PM (PST)' : 'BUY NEON'
      const buyButtonConditionally = location === 'info' ? null : <a className="cult-button cta" href={COLLECTION_URL} dangerouslySetInnerHTML={{__html: buyButtonText}}></a>;
      let LeftPaneContent = (
        <TextBox className="pane-profile">
          <TextBox className="cult-panel welcome-long">.// Welcome to the Cult of Neon</TextBox>
          <TextBox className="cult-panel welcome-short">.// Cult of Neon</TextBox>
          {/* {buyButtonConditionally} */}
          {/* NOTE : Am hiding this since I put the connect button here, but can use for future drop ads in next runs */}
        </TextBox>
      );

      if ( !!isWalletConnected && !!walletAddress ) {
        const length = walletAddress.length;
        const first = walletAddress.slice(0,2);
        const last = walletAddress.slice(length-8,length);
        cleanAddress = `${first}...${last}`;

        LeftPaneContent = (
          <TextBox className="pane-profile cult-panel profile-wallet-connected">
            { walletEns ? (
              <div className="address-ens">{walletEns}</div>
            ):(
              <div className="address-long">{cleanAddress}</div>
            )}

            { !!memberLevel && (
              <div className="member-level">Rank: {memberLevel}</div>
            )}
            
            {( !!memberCount || memberCount === 0 ) && (
              <div className="member-level">Owned: {memberCount}</div>
            )}
            {( walletEns || cleanAddress ) && (
              <div className="logout-button" onClick={this.logoutUser}>X</div>
            )}
         </TextBox>
        )
      }
      



// ~~~~ COMPONENT BODY
    return (
      <HeaderPanelWrap className={ `${enlightenmentMode ? 'enlightenment-mode' : ''} menu-${isMenuOpen ? 'open':'closed'}`}>

        {/* ROW 1 - Main Nav Content */}
        <HeaderRow>
          { /* LEFT PANE - PROFILE or TITLE */ }
          {LeftPaneContent}

          {/* MARKETING ONLY  */}
          {/* <div className="marketing-usage the-three-sampler cult-panel"></div> */}

          {/* CENTER PANE - LOCATION */
            !!location && (
              <TextBox className={`pane-location cult-panel current-location-${location}`}>{location === 'reliquary' ? 'MY NEON' : location}</TextBox>
            )
          }

          {/* RIGHT PANE - ABOUT LINK */}
          <div className="right-pane-content">
            <NavMenu/>
          </div>
        </HeaderRow>
        
        {/* ROW 2 - Error Spacer */}
        <HeaderRow className="alert-row">
          { /* ERROR ? >>> ERROR MSG */
            web3Error && (
              <TextBox className="pane-error cult-panel" onClick={this.clearErrorMsg}>{web3Error}<br/>(Tap to Dismiss)</TextBox>
            )
          }
        </HeaderRow>
      </HeaderPanelWrap>
    )
  }
};

export default HeaderPanel;
