import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';
import NftGalleryItem from './NftGalleryItem';

// UTILS
import { device } from "../../utils";
import { randomNum } from "../../utils/helperFn";


// ~~~~ STYLING
const NftGalleryWrap = styled.div`
  position: relative;
  width: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${device.xl} {
    justify-content: space-around;
  }
`;


class NftGallery extends React.Component {
  static contextType = LuxWeb3Context;

  render() {
    const { availableNFTs, NFTs } = this.context;
    const { mode = 'user' } = this.props;

    // NOTE: 
    //  NFTs = Users held NFTs
    //  availableNFTs = available collection NFTs

// ~~~~ COMPONENT BODY
    return (
      <NftGalleryWrap>
        {( mode === 'user' && !!NFTs && NFTs.length > 0 ) && 
          NFTs.map(nft => (
            <NftGalleryItem key={randomNum(999999999)} nft={nft} mode={mode}/>
          ))
        }

        {( mode === 'available' && !!availableNFTs && availableNFTs.length > 0 ) && 
          availableNFTs.map(nft => (
            <NftGalleryItem key={randomNum(999999999)} nft={nft} mode={mode}/>
          ))
        }
      </NftGalleryWrap>
    )
  }
};

export default NftGallery;
