import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';


// ~~~~ STYLING
const ItemPropertiesWrap = styled.div`

`;



class ItemProperties extends React.Component {
  static contextType = LuxWeb3Context;

  render() {
    const { mode, productTags } = this.props;

    // ~~~~ PRODUCT PROPERTIES - 2 MODES:
    //     'detail' -- uses the @prop- format for finding tags
    //     'preview' -- uses the @preview- format for finding tags
    let itemProperties = null;
    if ( !!productTags ) {
      const list = mode === 'preview' ? productTags.filter( tags => tags.search(/@preview-/gi) !== -1  ) : productTags.filter( tags => tags.search(/@prop-/gi) !== -1  );
      const parsedProperties = list.map( tag => {
        const property = mode === 'preview' ? tag.split('@preview-')[1] : tag.split('@prop-')[1];
        const data = property.split(':');
        return {
          name: data[0],
          value: data[1]
        }
      })
      itemProperties = parsedProperties.map( attr => (
        <div className="prop-item" key={attr.name}>
          <div className="prop-title">{attr.name}:</div>
          <div className="prop-value">{attr.value}</div>
        </div>
      ));
    }


// ~~~~ COMPONENT BODY
    return (
      <ItemPropertiesWrap className="cult-panel-colors">
        {itemProperties}
      </ItemPropertiesWrap>
    )
  }
};

export default ItemProperties;