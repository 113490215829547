import React from "react";
import EntryEye from "../sections/_lux/home/EntryEye";

const IndexPage = () => {
  const isSSR = typeof window === 'undefined' || typeof atob === 'undefined';

  return (
    <>
      {!isSSR && 
          <React.Suspense fallback={<div />}>
            <EntryEye /> 
          </React.Suspense>
      }
    </>
  );
};
export default IndexPage;
