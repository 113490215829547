import React from "react";
import styled from "styled-components";
import { device } from "../../../utils";

const ContentReliquaryWrap = styled.div`
  #reliquary-content 
    text-align: center; 

    .content-header {
      margin-bottom: 15px;
      font-size: 20px;
      text-align: center;

      @media ${device.md} {
          font-size: 24px;
      }

    }
    .content-body {
      font-size: 15px;
      text-align: center;

      @media ${device.md} {
        font-size: 18px;
      }
    }
  }
`;


const Reliquary = () => {
  return (
    <ContentReliquaryWrap>
      <ul id="reliquary-content">
          <li className="content-header">.// Welcome Cultist \\.</li>
          <li className="content-body">
            <i className="cult-link">"Downloads"</i> : Secret Schematics + more!
            <br/>
            <i className="cult-link">"Repair"</i> : Summon expert help
          </li>
        </ul>
    </ContentReliquaryWrap>
   
  );
};

export default Reliquary;