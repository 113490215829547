import React from "react";
import styled from "styled-components";
import Tunnel from '../../assets/image/svg/EyeTunnel_rect--has_eye.svg'

const AnimationWrap = styled.div`
  svg {
    width: auto;
    height: 100vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;


const EyeTunnel = (props) => {
  return (
    <AnimationWrap >
      <Tunnel style={props.dimensions} />
    </AnimationWrap>
   
  );
};

export default EyeTunnel;
