import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';


// ~~~~ STYLING
const NftMediaWrap = styled.div`
  position: relative;
  padding-top: 15px;

  .item-video,
  .item-image,
  .item-placeholder {
    position: relative;
    width: 100%;
    // box-shadow: 5px 6px 0px rgba(0, 0, 0, 0.75);
  }

  .item-image {
    img { 
      width: 100vw;
      max-width: 100%;
    }
  }

  .item-image-placeholder {
    height: 250px;
    background: #333;
  }
`;



class NftMedia extends React.Component {
  static contextType = LuxWeb3Context;


  render() {
    const { nft } = this.props;

// ~~~~ DATA FORMATTING:
    let mediaDisplay = (<div className='item-placeholder'></div>);

    if ( !!nft ) {
      const metadata = nft.rawMetadata || nft.metadata;
      const backupImgUrl = (!!nft.media[0] && !!nft.media[0].gateway) ? nft.media[0].gateway : ''; // OLD LOGIC, NOT A PLACEHOLDER IMAGE
      const mediaUrl = !!metadata ? metadata.image_url : backupImgUrl;
      const videoUrl = !!metadata ? metadata.animation_url : null;
    

      // MEDIA : Video Type
      if ( !!videoUrl ) {
        mediaDisplay = ( 
          // <video className='item-video' src={videoUrl} autoPlay loop /> 
          <video
            className='item-video'
            controls=""
            controlsList="nodownload" 
            autoPlay
            loop
            muted
            playsInline
            poster={ mediaUrl }
            src={ videoUrl } 
            type="video/mp4">
          </video>
        )
      }
  
      // MEDIA : Image Type
      else if ( !!mediaUrl ) {
        mediaDisplay =  (<div className='item-image'><img src={`${mediaUrl}`} alt="A Cult of Neon NFT Schematic" /></div>)
      }
    }
    


// ~~~~ COMPONENT BODY
    return (
      <NftMediaWrap>
        {mediaDisplay}
      </NftMediaWrap>
    )
  }
};

export default NftMedia;