import React from "react";
import { device } from "../../../utils";
import styled from "styled-components";
import LuxWeb3Context from '../../../context/LuxWeb3Context';

const LandingWrap = styled.div`
    text-align: center;

    .cult-button {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate( -50%, -50% );
        padding: 5px 14px 5px 20px;
        font-size: 22px;

        @media ${device.sm} {
          font-size: 26px;
        }

        @media ${device.md} {
          font-size: 28px;
        }
    }
`;

class Landing extends React.Component {
    static contextType = LuxWeb3Context;
    constructor(props) {
      super(props);
    }
  
  
    render() {
      const { setLocationShop } = this.context;
  
      return (
        <LandingWrap>
            <div onClick={setLocationShop} className="cult-button">[ Enter ]</div>
        </LandingWrap>
      );
    }
}
export default Landing;