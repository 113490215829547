import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';
import ShopGalleryItem from './ShopGalleryItem';

// UTILS
import { device } from "../../utils";
import { randomNum } from "../../utils/helperFn";


// ~~~~ STYLING
const ShopGalleryWrap = styled.div`
  position: relative;
  width: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${device.xl} {
    justify-content: space-around;
  }
`;


class ShopGallery extends React.Component {
  static contextType = LuxWeb3Context;

  render() {
    const { shopifyProducts } = this.context;

// ~~~~ COMPONENT BODY
    return (
      <ShopGalleryWrap>
        {( shopifyProducts.length > 0 ) && 
          shopifyProducts.map(product => (
            <ShopGalleryItem key={randomNum(999999999)} product={product} />
          ))
        }
      </ShopGalleryWrap>
    )
  }
};

export default ShopGallery;
