import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';
import EyeLogo from '../../assets/image/svg/eye_logo.svg';


// ~~~~ STYLING
const NavMenuWrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 100; // Content panel is at 1000, so 2000 jumps it on top cleanly

// MENU TAP TO CLOSE INVISIBLE DIV
    .menu-tap-to-close {
        position: absolute;
        width: 100vw;
        height: 100vh;
        opacity: 0;
        top: 0;
        left: 0;
        background: #000;
        transition: opacity 0.25s ease-in-out;
        pointer-events: none;
    }


// MENU BACKGROUND
    .menu-background {
        position: absolute;
        width: 156px;
        height: 410px;
        right: -160px;
        top: 0;
        opacity: 0;
        pointer-events: none;
        transition: all 0.25s ease-in-out;
    }
    &.wallet-connected .menu-background {
        height: 450px;
    }


// NAV CONTENT ITEMS
    .nav-content-wrap {
        position: relative;
        top: 15px;
        right: -165px;
        opacity: 0;
        transition: all 0.25s ease-in-out;
        pointer-events: none;

        .menu-option {
            width: 131px;
            font-size: 21px;
            line-height: 22px;
            padding: 9px 10px 9px 15px;
            margin-bottom: 15px;
        }
    }


// OPEN ANIMATION STYLE ALIGNMENTS
    &.open {
      z-index: 2000;

      .pane-button.main-button {
          padding: 2px 10px 4px 15px; //Adjust for the X to fit right
      }

      .menu-tap-to-close {
          opacity: 0.35;
          cursor: pointer;
          pointer-events: all;
      }

      .menu-background {
          right: 0;
          opacity: 1;
      }
      .nav-content-wrap {
          right: 0;
          opacity: 1;
          pointer-events: all;
      }
      .pane-button.enlightenment {
          right: 87px;
          opacity: 1;
          pointer-events: all;
      }
    }

    &.enlightenment-on {
        .pane-button.enlightenment {
            right: 13px;
            opacity: 1;
            pointer-events: all;
            z-index: 200;
        }
    }
`;

const TextBox = styled.div`
  /* BUTTON MAIN STYLES */
  &.pane-button {
    cursor: pointer;
    font-size: 42px;
    padding: 0px 10px 6px 15px;
    line-height: 44px;
    transition: opacity 0.25s ease-in-out;

    &.main-button {
        max-width: 57px;
        z-index: 100;
        text-overflow: clip;
    }

    &:hover {
      background: #eee !important;
      color: #000 !important;
      text-shadow: 2px 2px 0px rgba(0,0,0,0) !important;
    }
  }


  /* PANE BUTTON : ENLIGHTENMENT */
  &.enlightenment {
    position: absolute;
    top: 10px;
    right: 13px;
    width: 57px;
    height: 56px;
    z-index: 50;
    opacity: 0;
    transition: all 0.25s ease-in-out;

    svg {
      display: none;
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    
    // Pixel Eye
    .enlighten-eye {
      position: absolute;
      width: 28px;
      height: 28px;
      top: 26px;
      left: 50%;
      padding: 0;
      border: 2px solid #333;
      transform: translate( -50%,-50% );
      box-shadow: none;
      
      &:hover {
        background-color: #bbb;
      }

      .enlighten-pupil {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 6px;
        left: 6px;
        border: 1px solid #222;
        padding: 0;
        box-shadow: none;

        &:hover {
          background-color: #555;
        }
      }
    }
  }
`;


class NavMenu extends React.Component {
  constructor( props ) {
    super( props );
    this.toggleEnlightenment = this.toggleEnlightenment.bind(this);
    this.clearErrorMsg = this.clearErrorMsg.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.navToShop = this.navToShop.bind(this);
    this.navToInfo = this.navToInfo.bind(this);
    this.navToLegal = this.navToLegal.bind(this);
    this.navToContact = this.navToContact.bind(this);
    this.navToMyNeon = this.navToMyNeon.bind(this);
  }
  static contextType = LuxWeb3Context;

  // NAV : Enlightenment Mode Toggler   
  toggleEnlightenment() {
    const { enlightenmentMode, setEnlightenmentMode } = this.context;
    setEnlightenmentMode( !enlightenmentMode );
    this.toggleMenu();
  }

  clearErrorMsg() {
    const { setWalletConnectError } = this.context;
    setWalletConnectError(null);
  }

  logoutUser() {
    window.location.reload();
  }

  // NAV : Open / Close the menu
  toggleMenu() {
    this.context.setMenuOpen( !this.context.isMenuOpen );
  }

  // NAV DESTINATION CONTROLS (Yes slightly lame, but faster / easier)
  navToShop() {
    this.context.setMenuOpen( false );
    this.context.setLocationShop();
  }
  navToInfo() {
    this.context.setMenuOpen( false );
    this.context.setLocationInfo();
  }
  navToLegal() {
    this.context.setMenuOpen( false );
    this.context.setLocationLegal();
  }
  navToContact() {
    this.context.setMenuOpen( false );
    this.context.setLocationContact();
  }

  navToMyNeon() {
    this.context.setMenuOpen( false );

    // If no held neon, send to initiate Mode of shop view
    this.context.memberCount < 1 ? (
        this.context.setLocationInitiate()
    ) : (
        this.context.setLocationReliquary()
    );
  }

  logoutUser() {
    window.location.reload();
  }


  render() {
    const { 
      connectWallet,
      enlightenmentMode,
      isMenuOpen,
      isWalletConnected, 
    } = this.context;


// ~~~~ NAV BODY
    return (
        <NavMenuWrapper className={ `${isMenuOpen ? 'open':'closed'} ${enlightenmentMode ? 'enlightenment-on': ''} ${isWalletConnected ? 'wallet-connected': ''}` }>

            {/* TAP OUT TO CLOSE FUNCTIONALITY */}
            <div onClick={this.toggleMenu} className="menu-tap-to-close"></div>

            {/* BACKGROUND */}
            <div className="menu-background cult-panel cult-button-colors"></div>
            
            {/* BUTTON : MAIN TOGGLE */}
            <TextBox onClick={this.toggleMenu} className={`pane-button main-button cult-panel`} >{ isMenuOpen ? 'X' : '=' }</TextBox>

            {/* BUTTON : HIDE ALL (Return to Landing) */}
            <TextBox onClick={this.toggleEnlightenment} className="pane-button cult-panel enlightenment">
              <EyeLogo />
              <div className="cult-panel enlighten-eye cult-eye-ball">
                <div className="cult-panel enlighten-pupil cult-eye-pupil"></div>
              </div>
            </TextBox>

            {/* MENU CONTENT */}
            <div className="nav-content-wrap">

                {/* NAV LINKS */}
                <TextBox onClick={this.navToShop} className={`pane-button menu-option link-shop cult-panel`}>Shop</TextBox>
                <TextBox onClick={this.navToInfo} className={`pane-button menu-option link-info cult-panel`}>Info</TextBox>
                <TextBox onClick={this.navToLegal} className={`pane-button menu-option link-legal cult-panel`}>Legal</TextBox>
                <TextBox onClick={this.navToContact} className={`pane-button menu-option link-contact cult-panel`}>Contact</TextBox>
                
                { /* CONNECT BUTTON */}
                { !isWalletConnected ? (
                    <TextBox onClick={connectWallet} className={`pane-button menu-option link-connect cult-panel`}>Connect Wallet</TextBox>
                ) : (
                    <TextBox onClick={this.navToMyNeon} className={`pane-button menu-option link-reliquary cult-panel`}>My Neon</TextBox>
                )}

                { /* DISCONNECT BUTTON */}
                { isWalletConnected ? (
                    <TextBox onClick={this.logoutUser} className={`pane-button menu-option link-reliquary cult-panel`}>Log Out</TextBox>
                  ):(
                    ''
                )}
            </div>
        </NavMenuWrapper>
    )
  }
};

export default NavMenu;
