import React from "react";
import { device } from "../../../utils";
import styled from "styled-components";
import { COLLECTION_URL, INSTAGRAM_URL, TWITTER_URL, OPENSEA_URL, FOUNDATION_COLLECTION_URL } from "../../../constants";
import logo_instagram from '../../../assets/image/cult/social_instagram.png';
import logo_twitter from '../../../assets/image/cult/social_twitter.png';
import logo_foundation from '../../../assets/image/cult/social_foundation.png';
import logo_opensea from '../../../assets/image/cult/social_opensea.png';
import logo_discord from '../../../assets/image/cult/social_discord.png';

const ContentAboutWrap = styled.div`
    position: relative;

    // HEADER
    .content-header {
      margin-bottom: 10px;
      font-size: 20px;
      text-shadow: 0 0 0 #000;
      cursor: pointer;
      text-align: center;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      max-width: 400px;
      width: 95%;
      display:none;

      @media ${device.sm} {
        font-size: 24px;
        display: none;
      }

      @media ${device.md} {
        font-size: 27px;
        margin-bottom: 10px;
        display: none;
      }

      @media ${device.lg} {
        font-size: 30px;
        display: block;
      }

      &.cult-button {
        margin-bottom: 15px;
      }
    }


    .content-body {
      font-size: 16px;
      padding: 0 5px;
      text-align: center;
      
      @media ${device.lg} {
        font-size: 20px;
        padding: 0 90px;
      }

      &.content-entry {
        @media ${device.md} {
          font-size: 22px;
        }
      }

      .content-image {
        width: 100%;
      }

      .text-bold {
        text-shadow: 0px 0px 1px #000;
      }

      &.drop-preview {
        font-size: 18px;
        display: flex;
        justify-content: center;
        padding: 15px 0;
        pointer-events: none;

        @media ${device.md} {
          font-size: 26px;
        }

        .cult-button {
          width: 95%;
          max-width: 400px;
          font-size: 18px;
          margin: 15px 0 10px;

          @media ${device.md} {
            font-size: 24px;
          }
        }
      }
    }

    .hide-medium {
        @media ${device.md} {
            display: none;
        }
    }

    // SOCIAL LINKS
    .content-link-wrap {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 95%;
      max-width: 400px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin: 15px 0 0;

      @media ${device.md} {
        margin: 25px 0 0;
      }
  
      a { 
        display: inline-flex;   
        width: 40px;
        height: 40px;
        &.instagram { background: rgba(0, 0, 0, 0) url("${logo_instagram}") no-repeat scroll 50% 50% / contain; }
        &.twitter { background: rgba(0, 0, 0, 0) url("${logo_twitter}") no-repeat scroll 50% 50% / contain; }
        &.discord { background: rgba(0, 0, 0, 0) url("${logo_discord}") no-repeat scroll 50% 50% / contain; }
        &.foundation { background: rgba(0, 0, 0, 0) url("${logo_foundation}") no-repeat scroll 50% 50% / contain; }
        &.opensea { background: rgba(0, 0, 0, 0) url("${logo_opensea}") no-repeat scroll 50% 50% / contain; }

        @media ${device.md} {
          width: 60px;
          height: 60px;
        }
  
        &:hover {
          opacity: 0.6;
        }
      }
    }

    .content-no-nfts {
      font-size: 22px !important;
      margin-top: 10px;
      margin-bottom: 30px;
      pointer-events: none;

      @media ${device.md} {
        font-size: 32px !important;
      }
    }
  
    // UTIL
    .text-center {
      text-align: center;
    }
`;

class ContentAbout extends React.Component {
  constructor(props) {
    super(props);
  }


  render() {
    return (
      <ContentAboutWrap>
        <li className="content-header">.// CULT OF NEON \\.</li>
        <li className="content-entry content-body text-center">Sentinels of the human realm, we monitor and catalog the luminous spirits that dwell within our world unseen. Humanitys' collective, emotional energy conjures these neon spirits, and we are stewards of the occultic archive.</li>
        <br/>
        {/* NEW DROP TEASER */}
        <li className="content-entry content-body text-center drop-preview"><div className="cult-button">NEW DROP - NOW LIVE!<br/>[ Bonsai ]</div></li>
        <li className="content-link-wrap">
            <a className="twitter" href={TWITTER_URL} target="_blank" rel="noreferrer"></a>
            <a className="instagram" href={INSTAGRAM_URL} target="_blank" rel="noreferrer"></a>
            {/* <a className="foundation" href={FOUNDATION_COLLECTION_URL}></a> */}
            <a className="opensea" href={OPENSEA_URL}></a>
        </li>
      </ContentAboutWrap>
    );
  }
};

export default ContentAbout;