import React from "react";
import { device } from "../../utils";
import styled from "styled-components";
import { FOUNDATION_COLLECTION_URL, INSTAGRAM_URL, TWITTER_URL, OPENSEA_URL } from "../../constants";
import logo_instagram from '../../assets/image/cult/social_instagram.png';
import logo_twitter from '../../assets/image/cult/social_twitter.png';
import logo_foundation from '../../assets/image/cult/social_foundation.png';
import logo_opensea from '../../assets/image/cult/social_opensea.png';

const ForgeInProgressWrap = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
  flex-wrap: wrap;
  text-align: left;

  @media ${device.md} {
    width: 40%;
  }

  .content-header {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

  .content-body {
    font-size: 18px;
  }

  .content-link-wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;

    a { 
      display: inline-flex;   
      width: 60px;
      height: 60px;
      &.instagram { background: rgba(0, 0, 0, 0) url("${logo_instagram}") no-repeat scroll 50% 50% / contain; }
      &.twitter { background: rgba(0, 0, 0, 0) url("${logo_twitter}") no-repeat scroll 50% 50% / contain; }
      &.foundation { background: rgba(0, 0, 0, 0) url("${logo_foundation}") no-repeat scroll 50% 50% / contain; }
      &.opensea { background: rgba(0, 0, 0, 0) url("${logo_opensea}") no-repeat scroll 50% 50% / contain; }

      &:hover {
        opacity: 0.6;
      }
    }
  }

  .content-link {
    text-align: center;
    cursor: pointer;
  }

  button.cult-button {
    width: 100%;
    font-size: 24px;
  }
`;

const ForgeInProgress = (props) => {
  return (
    <ForgeInProgressWrap>
      <ul>
        <li className="content-header">*// CLAIMED \\*</li>
        <li className="content-body">Thanks for joining Cult of Neon!</li>
        <br/>
        <li className="content-body">As a member, you'll get early access info on all future drops, as well as allowlist slot(s) once we're ready for the Generative Series - Sacred Artifacts.</li>
        <br/>
        <li className="content-body">We stand by our quality, need help or questions? We're here for you! <a className="email cult-link" href="mailto:cultofneon.art@gmail.com">cultofneon.art@gmail.com</a></li>
        <br/>
        <li className="content-link-wrap">
          <a className="instagram" href={INSTAGRAM_URL} target="_blank" rel="noreferrer"></a>
          <a className="twitter" href={TWITTER_URL} target="_blank" rel="noreferrer"></a>
          <a className="foundation" href={FOUNDATION_COLLECTION_URL} target="_blank" rel="noreferrer"></a>
          <a className="opensea" href={OPENSEA_URL} target="_blank" rel="noreferrer"></a>
        </li>
        <br/>
        <button className="cult-button" onClick={props.finish}>Back</button>
      </ul>
    </ForgeInProgressWrap>
  );
};

export default ForgeInProgress;