// SHOPIFY - Button Integration
import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';

const BuyButton = styled.a`
  &.sold-out,
  &.sold-out:hover {
    pointer-events: none;
    background: #DFDFDF !important;
    color: #000 !important;
    text-shadow: none;
  }
`;



class ShopifyBuyButton extends React.Component {
  static contextType = LuxWeb3Context;
  constructor( props ) {
    super( props );
  }

  render() {
    const { product } = this.props
    let buttonText = product.is_available ? `Add to Cart` : 'Sold Out'
    if (product.tags.indexOf('coming-soon') > -1) {
      buttonText = 'Coming Soon'
    }

    return (
      <>
        <BuyButton 
          className={`action-button cult-button ${product.is_available ? '' : 'sold-out'}`} 
          href={`https://cultofneon.myshopify.com/cart/${product.variant_id}:1?channel=buy_button`}>{buttonText}</BuyButton>
      </>
    )
  }
}

export default ShopifyBuyButton
