import React from "react";

const Contact = () => {
  return (
    <div>
      <li className="content-body">Questions? DM <a className="instagram cult-link" target="_blank" href="https://instagram.com/lux_capacitor">@Lux_Capacitor</a><br/>or email us at <br/><a className="email cult-link" href="mailto:cultofneon.art@gmail.com">cultofneon.art@gmail.com</a></li>
      <br/>
    </div>
  );
};

export default Contact;