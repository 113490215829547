import React from "react";
import { device } from "../../../utils";
import styled from "styled-components";
import LuxWeb3Context from '../../../context/LuxWeb3Context';

const ContentLandingWrap = styled.div`
    text-align: center;

    .landing-content { 
        padding-bottom: 18px; 
        max-width: 780px;
        margin-bottom: 0px;

        .content-header {
            font-size: 20px;
            margin-bottom: 15px;
            display: none;

            @media ${device.sm} {
                display: block;
            }
    
            @media ${device.md} {
                font-size: 24px;
            }
        }
    
        .content-body {
            font-size: 16px;
            padding-top: 20px;

            @media ${device.sm} {
                display: block;
            }
    
            @media ${device.md} {
                font-size: 18px;
            }

            .cult-link.shop-header-text {
                font-size: 20px;

                @media ${device.md} {
                    font-size: 22px;
                }
            }

            .cult-button { 
                font-size: 15px;

                @media ${device.md} {
                    font-size: 18px;
                }

                @media ${device.lg} {
                    font-size: 24px;
                }
            }
        }

    }
`;

class Landing extends React.Component {
    static contextType = LuxWeb3Context;
    constructor(props) {
      super(props);
    }
  
  
    render() {
      const { noNftsFound = false } = this.props;
      const { setLocationInfo } = this.context;

      // Action : Learn More or "No Schematics Found" Msg  
      const content = !noNftsFound ? (
        // <li className="content-body"><br/><button className="cult-button" onClick={setLocationInfo}>Learn More</button></li>
        <li className="content-body">
            {/* All neon include the schematic used to bend the artwork, which also serves as your certificate of authenticity. */}

            {/* NORMAL HEADER TEXT */}
            {/* <i className="cult-link">"Afflicted Objects"</i> are born when household items are inhabited by Neon spirits. The cult dedicates itself to cataloging these oddities. */}
            
            {/* BONSAI PROMO */}
            <i className="cult-link shop-header-text">~.// SPIRITS DETECTED \\.~</i><br/>Reports of Neon Spirits inhabiting ancient Bonsai in Kyoto...<br/>
        </li>
      ) : (
        <li className="content-body content-no-nfts text-center cult-link"><br/>No Neon Schematic NFTs Found in Connected Wallet</li>
      )
  
      return (
        <ContentLandingWrap>
            <ul className="landing-content">
                { content }
            </ul>
        </ContentLandingWrap>
      );
    }
}
export default Landing;