import React from 'react'
import styled from "styled-components";
import LuxWeb3Context from '../../context/LuxWeb3Context';


// ~~~~ STYLING
const ItemMediaWrap = styled.div`
  position: relative;
  padding-top: 15px;

  .item-video,
  .item-image,
  .item-placeholder {
    position: relative;
    width: 100%;
    // box-shadow: 5px 6px 0px rgba(0, 0, 0, 0.75);
  }

  .item-image {
    img { 
      width: 100vw;
      max-width: 100%;
    }
  }

  .item-image-placeholder {
    height: 250px;
    background: #333;
  }


  // MEDIA PICKER
  .media-picker-wrap {
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: center;

    .item-thumbnail {
      width: 20%;
      padding: 2px;
      cursor: pointer;

      &:nth-child(5n-4) { padding-left: 0px; }
      &:last-child(5n)  { padding-right: 0px;}

      &.selected img {
        opacity: 0.6;
      }

      img {
        width: 100%;
        cursor: pointer;
        &:hover {
          opacity: 0.15;
        }
      }
    }
  }
`;



class ItemMedia extends React.Component {
  static contextType = LuxWeb3Context;
  constructor(props) {
    super(props);
    this.state = {
      currentImg: null,
      images: []
    }
  }

  componentDidMount(props) {
    if ( !!this.props.product && !!this.props.product.images ) {
      this.setState({ 
        images: this.props.product.images,
        currentImg: this.props.product.images[0].src
      })

      this.selectImage = this.selectImage.bind(this);
    }
  }

  selectImage(e) {
    this.setState({
      currentImg: e.target.src
    })
  }

  render() {
    const { product } = this.props;
    const { images, currentImg } = this.state;

// ~~~~ DATA FORMATTING:
    let mediaDisplay = (<div className='item-placeholder'></div>);
    let mediaSelector = '';
    let videoUrl = null;

    if ( !!product && !!product.images ) {

      // MEDIA : Video Type
      if ( !!videoUrl && !!currentImg) {
        mediaDisplay = ( 
          <video
            className='item-video'
            controls=""
            controlsList="nodownload" 
            autoPlay
            loop
            muted
            playsInline
            poster={ currentImg }
            src={ videoUrl } 
            type="video/mp4">
          </video>
        )
      }
  
      // MEDIA : Image Type
      else if ( !!currentImg ) {
        mediaDisplay =  (<div className='item-image'><img src={`${currentImg}`} alt="A Cult of Neon artwork" /></div>)
      }

      
      // SELECTOR : Pick other images - USE WITH YOUR PICKER
      const items = images/*.filter(img => img.src !== currentImg)*/.map( (img, index) => {
        return (
          <div onClick={this.selectImage} src={img.src} key={`item-thumbnail-${index}`} className={`item-thumbnail ${img.src === currentImg ? 'selected' : ''}`}>
            <img src={img.src} alt="Image picker" />
          </div>
        )
      })
      
      mediaSelector = (
        <div className="media-picker-wrap">{items}</div> // MY PICKER SIMPLER VERSION
      )
    }
    


// ~~~~ COMPONENT BODY
    return (
      <ItemMediaWrap>
        {mediaDisplay}
        {mediaSelector}
      </ItemMediaWrap>
    )
  }
};

export default ItemMedia;