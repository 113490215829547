import React from 'react'
import styled from "styled-components";
import { marked } from 'marked';
import LuxWeb3Context from '../../context/LuxWeb3Context';
import { device } from "../../utils";



// OUTERMOST WRAPPER
const DescriptionWrapper = styled.div`
  hr {
    // border: 1px;
    border-top: 1px solid #000;
  }

  .content-header {
    margin-top: 10px;
  }
`;

// ~~~~ STYLING
const NftDescriptionWrap = styled.div`
  position: relative;
  padding: 15px 10px;
  text-align: left;

  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .title-name {
    // Not used really title looks fine, but there for desire..
  }

  hr {
    border-top: 1px solid #000;
  }
`;

// NFT DOWNLOAD LIST STYLING
const NftDownloadsList = styled.ul`

  // SINGLE DOWNLOAD ROW 
  .content-download-item {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    padding: 0 10px;
    

    .item-key {
      font-size: 15px;

      @media ${device.sm} {
        font-size: 18px;
      }
    }

    .item-link {
      position: absolute;
      right: 15px;
      font-size: 15px;

      @media ${device.sm} {
        font-size: 18px;
      }
    }
  }
`;



class NftDescription extends React.Component {
  static contextType = LuxWeb3Context;


  render() {
    const { description, downloads = [], title } = this.props;
    const mergedDescription = `<div class="title-name">${title}</div> ${description}`;
    const cleanDescription = mergedDescription.replace(/\[http:\/\/cultofneon.art\]/gi, 'https://cultofneon.art');

    // DOWNLOADS POPULATOR
    let downloadList = null;
    if (downloads.length > 0) {

      // DOWNLOAD ITEM TEMPLATE
      downloadList = (
        <NftDownloadsList id="nft-downloads-wrap">
          <li className="content-header">.// Downloads \\.</li>
            {downloads.map( (item,index) => (
              <li className="content-download-item" key={index}>
                <div className="download-item item-key">{item.title}</div>
                <a className="download-item item-link cult-button" href={item.link} target="_blank">VIEW</a>
              </li>
            ) )}
        </NftDownloadsList>)
    }


// ~~~~ COMPONENT BODY
    return (
      <DescriptionWrapper>
        {downloadList}
        <hr></hr>
        <p className="content-header">.// CASE FILE \\.</p>
        <NftDescriptionWrap className="cult-panel-colors" dangerouslySetInnerHTML={{__html: marked.parse( cleanDescription ) }}></NftDescriptionWrap>
      </DescriptionWrapper>
    )
  }
};

export default NftDescription;